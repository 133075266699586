import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import TableColumnWrapper from '../../PrintUtilities/TableColumnWrapper'
import TableRowWrapper from '../../PrintUtilities/TableRowWrapper'
import LineBuffer from './LineBuffer'
import { LineBufferProps, LineBufferState } from './_types'
import { mapChartStateToProps } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/state'
import { DEFAULT_STATE, DENSIFIERS_IDS } from './_constants'
import BufferLineChartPrint from './_BufferLineChartSingleLine/BufferLineChartSingleLinePrint'
import LineBufferLegend from './_BufferLineChartLegend/BufferLineChartLegend'
import { StretchColumn } from '@mv-submodules/mvlabs-components-fe/ui/components'
class LineBufferPrint extends LineBuffer<LineBufferProps, LineBufferState> {
  constructor(props: LineBufferProps) {
    super(props, () => DEFAULT_STATE)
  }

  private showMillAbsorptionState(lineNumber: number) {
    return this.state.filteredData[0][`mill${lineNumber}AbsorptionState` as keyof typeof this.state.filteredData]
  }

  public renderContent() {
    const { t } = this.props

    return (
      <TableRowWrapper>
        <TableColumnWrapper col={'1/4'}>
          <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.linesStatusTitle')}</h3>
          <img src={'https://inplant-public.s3.eu-west-1.amazonaws.com/dec-bluair.png'} height={250} />
        </TableColumnWrapper>
        <TableColumnWrapper col={'3/4'}>
          <StretchColumn spaceSize="md">
            <StretchColumn spaceSize="none">
              {DENSIFIERS_IDS.map(number => (
                <StretchColumn spaceSize="none" key={`densifier-${number}`}>
                  <h5 className={'w-100 mt-3'}>
                    {this.props.t(`plantAnalysis.lines.linesStatus.buffers.buffer${number}`)}
                  </h5>
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <div style={{ padding: '4px 5px 0 0', width: '15px' }}>
                      <span>1.</span>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <BufferLineChartPrint
                        topMargin={true}
                        entry={`buffer${number}State`}
                        colorsId={'activeStatus'}
                        filteredData={this.state.filteredData}
                        i18nTitle={`plantAnalysis.lines.linesStatus.buffers.buffer${number}`}
                        lineHeight={30}
                        hideTitle={true}
                        hideTimeline={true}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <div style={{ padding: '4px 5px 0 0', width: '15px' }}>
                      <span>2.</span>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <BufferLineChartPrint
                        topMargin={true}
                        entry={`dec${number}TorqueState`}
                        colorsId={'densifierTorqueStatus'}
                        filteredData={this.state.filteredData}
                        i18nTitle={`plantAnalysis.lines.linesStatus.dec.dec${number}`}
                        lineHeight={30}
                        hideTitle={true}
                        hideTimeline={true}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <div style={{ padding: '4px 5px 0 0', width: '15px' }}>
                      <span>3.</span>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <BufferLineChartPrint
                        topMargin={true}
                        entry={`buffer${number}Fill`}
                        colorsId={'fillStatus'}
                        filteredData={this.state.filteredData}
                        i18nTitle={`plantAnalysis.lines.linesStatus.buffers.buffer${number}`}
                        lineHeight={30}
                        hideTitle={true}
                        hideTimeline={true}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <div style={{ padding: '4px 5px 0 0', width: '15px' }}>
                      <span>4.</span>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <BufferLineChartPrint
                        topMargin={true}
                        entry={`buffer${number}LoadState`}
                        colorsId={'bufferState'}
                        filteredData={this.state.filteredData}
                        i18nTitle={`plantAnalysis.vecoplant.bufferState.buffers.buffer${number}`}
                        lineHeight={30}
                        hideTitle={true}
                        workshift={this.props.workShift}
                        hideTimeline={this.showMillAbsorptionState(number)}
                      />
                    </div>
                  </div>
                  {this.showMillAbsorptionState(number) && (
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <div style={{ padding: '4px 5px 0 0', width: '15px' }}>
                        <span>5.</span>
                      </div>
                      <div style={{ flexGrow: 1 }}>
                        <BufferLineChartPrint
                          topMargin={true}
                          entry={`mill${number}AbsorptionState`}
                          colorsId={'millAbsorptionState'}
                          filteredData={this.state.filteredData}
                          i18nTitle={`plantAnalysis.vecoplant.bufferState.buffers.buffer${number}`}
                          lineHeight={30}
                          hideTitle={true}
                          workshift={this.props.workShift}
                        />
                      </div>
                    </div>
                  )}
                </StretchColumn>
              ))}
            </StretchColumn>
            <LineBufferLegend t={t} />
          </StretchColumn>
        </TableColumnWrapper>
      </TableRowWrapper>
    )
  }
}

export default connect(mapChartStateToProps)(withTranslation()(LineBufferPrint))
