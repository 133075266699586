const it = {
  plantAnalysis: {
    recipeSlug: {
      plant: 'L1-2-5 Lavorazioni',
      'u2-phase2': 'L3-4 Lavorazioni',
    },
    buffer: {
      bufferLoadingTitle: 'Buffer in caricamento',
    },
    recipe: 'Materiali',
    commonTime: 'Tempo',
    aspirationSystem: {
      U350_airFlow: 'Portata attuale aria U350',
      U350_powder: 'Concentrazione polveri attuale U350',
      U352_airPressureIn: 'Pressione differenziale ingresso U352',
      U352_airPressureOut: 'Pressione differenziale uscita U352',
      U357_airFlow: 'Portata attuale aria U357',
      U357_powder: 'Concentrazione polveri attuale U357',
      U358_airPressure: 'Pressione differenziale U358',
      U359_airPressure: 'Pressione differenziale U359',
      e1Title: 'E1 Vaglio rotante e trasporto pneumatico (U350-U352)',
      e2Title: 'E2 Aria ambiente (U357-U358-U359)',
      U350_airFlowThreshold: 'Limite portata attaule aria U350',
      U357_airFlowThreshold: 'Limite portata attuale aria U357',
    },
    pressDetail: {
      recipeInformation: 'I dati visualizzati qui sotto variano a seconda del materiale selezionato',
      times: 'Tempi',
      baleDetail: 'Dettagli balla',
      productionAndConsumption: 'Produzione e consumo',
      generalOilTemperature: 'Temperatura olio',
      generalChannelPressure: 'Pressione canale',
      generalCartPressure: 'Pressione carrello',
      balesLength: 'Lunghezza balla',
      numberOfBaleHourly: 'Numero balle',
      unitWeight: 'Peso unitario',
      absorption: 'Assorbimento',
      tons: 'Tonnellate prodotte',
      workingTime: 'Tempo produzione',
      notWorkingTime: 'Tempo arresto',
      totalProduction: 'Num balle prodotte',
      totalTonsProduced: 'Tot produzione (ton)',
    },
    rppTableIdeal: {
      totalBales: 'Totale balle',
      totalTons: 'Peso totale',
      totals: 'Totali',
      recipeName: 'Nome ricetta',
      avgWeight: 'Peso medio',
    },
    dedicatedGraph: {
      speed: 'Velocità nastro di carico',
      torque: 'Coppia densificatore',
      closeOpen: 'Posizione valvola',
      absorption: 'Assorbimento',
    },
    linesTitles: {
      Line01: 'Linea 1',
      Line02: 'Linea 2',
      Line03: 'Linea 3',
      Line04: 'Linea 4',
      Line05: 'Linea 5',
    },
    recipeRecap: {
      actual: 'Eseguita',
      programmedTimeline: 'Pianificata',
      isHalt: 'Fermo manutentivo',
      noConnection: '---',
      noConnectionLabel: 'Connessione assente',
      noScheduledActivity: 'Nessuna attività programmata',
      noExecutedActivity: 'Nessuna attività eseguita',
      table: {
        expectedRecipe: 'Pianificata',
        actualRecipe: 'Eseguita',
        startEndTime: 'Orario',
        percentage: '%',
        duration: 'Durata',
        comment: 'Commento',
        connected: 'Connessa',
      },
      connection: {
        isConnected: 'Sì',
        isNotConnected: 'No',
      },
    },
    titles: {
      'ric-sg': 'San Giorgio Bluair',
      ri: "Rive D'arcano",
      cm: 'Cairo Montenotte',
      go: 'Godega',
      ac: 'Acquapendente',
    },
    noDataAvailable: 'Non ci sono dati presenti nel periodo selezionato',
    noNodesSelected: 'Seleziona i nodi da visualizzare con il tasto "Personalizza vista"',
    invalidDateRangeForThisChart: 'Visibile solo selezionando singola giornata', // disponibile singola giornata
    loading: 'Richiesta dati...',
    fetchErrors: "Errore caricando i dati, riprova o contatta l'amministratore",
    noNodes: "Non ci sono elementi per l'impianto selezionato",
    dateChangedDayBefore: 'Attenzione!\nLa data è cambiata dal {{fromDate}} al {{toDate}}',
    noChartData: 'Non ci sono dati disponibili',
    invalidChartConfiguration: 'Configurazione grafici nodo non valida',
    mean: 'Media',
    noOverviewLayout: 'Vista panoramica non disponibile per questo impianto',
    navigation: {
      plantAnalysis: 'Plant Analysis',
      plantAnalysisGeneral: 'Panoramica',
      plantAnalysisList: 'Dettaglio',
    },
    formats: {
      timeHHMM: '{{hh}} ore {{mm}} min',
      timeMMSS: '{{mm}} min {{ss}} sec',
      timeHH: '{{hh}} ore',
      timeMM: '{{mm}} min',
      timeSS: '{{ss}} sec',
    },
    dailyTable: {
      time: 'Orario',
      realTime: 'Reale',
      budgetTime: 'Budget',
      deltaTime: 'Delta',
      workshift: 'turno',
      status: 'Validità turno',
    },
    modal: {
      title: 'Impostazioni',
      close: 'Chiudi',
      save: 'Salva',
      showViewSettings: 'Personalizza vista',
    },
    settings: {
      main: {
        title: 'Generale',
      },
      nodes: {
        title: 'Nodi',
        all: 'Tutti',
      },
      fields: {
        soglia_deviazione: 'Deviazione',
        sforamento_assorbimento_vent: 'Limite Assorb. Aspir.',
        sforamento_assorbimento_nastri: 'Limite Assorb. Nastri',
        limite_amperaggio: 'Limite Assorb.',
        velocita_riferimento: 'Velocità rif.',
        soglia_under_speed: 'Soglia aprisacco al minimo',
        soglia_over_speed: 'Soglia aprisacco al massimo',
      },
    },
    actions: {
      selectDateText: 'Seleziona ...',
      selectWorkshiftText: 'Seleziona turno ...',
      exportDetails: 'Esporta report dettagliato',
      print: 'STAMPA',
    },
    labels: {
      command: 'Comando',
      program: 'Programma',
      pressure: 'Pressione',
      sync: 'Sincronizzazione',
      beltSpeed: 'Velocità nastro',
      direction: "Av/Ind",
      valvePosition: 'Posizione valvola',
      mills1Threshold: 'Soglia 1',
      mills2Threshold: 'Soglia 2',
      mills5Threshold: 'Soglia 3',
      materialTemperature: 'Temperatura materiale',
      waterFlow: "Portata d'acqua",
      screwRotSpeed: 'Velocità rotazione vite',
      running: 'In funzione',
      densifierBeltState: "Stato nastro",
      driveSpeed: "Velocità",
      bufferThreshold: 'Soglia intermedia',
      bufferVAlues: 'Riempimento buffer',
      buffer1Threshold: 'Soglia intermedia buffer 1',
      buffer1VAlues: 'Riempimento buffer 1',
      buffer2Threshold: 'Soglia intermedia buffer 2',
      buffer2VAlues: 'Riempimento buffer 2',
      buffer3Threshold: 'Soglia intermedia buffer 3',
      buffer3VAlues: 'Riempimento buffer 3',
      buffer4Threshold: 'Soglia intermedia buffer 4',
      buffer4VAlues: 'Riempimento buffer 4',
      buffer5Threshold: 'Soglia intermedia buffer 5',
      buffer5VAlues: 'Riempimento buffer 5',
      thrustPresser: 'Spinta pressore',
      speedPercent: 'Velocità',
      absorption: 'Assorbimento',
      amperage: 'Amperaggio',
      rotorSpeed: 'Velocità del rotore',
      pressorThrust: 'Spinta del pressore',
      materialTemp:"Temp. materiale",
      key1Inserted: 'Abilitazione totale impianto',
      key2Inserted: 'Manutenzione 1',
      key3Inserted: 'Manutenzione 2',
      key4Inserted: 'Manutenzione 3',
      key5Inserted: 'Manutenzione 4',
      key6Inserted: 'Manutenzione 5',
      key7Inserted: 'Abilitazione impianto',
      key8Inserted: 'Abilitazione linea 1',
      key9Inserted: 'Abilitazione linea 2',
      key10Inserted: 'Abilitazione linea 3',
      key11Inserted: 'Abilitazione linea 4',
      key12Inserted: 'Abilitazione linea 5',
      key13Inserted: 'Abilitazione caricamento F001',
      key14Inserted: 'Abilitazione caricamento F500',
      bufferLoadedWithF015: 'Buffer caricato con F015',
      bufferLoadedWithF070: 'Buffer caricato con F070',
      decOPMode: "Modalità operativa",
      last2hAverageTorque: "Media coppia ultime 2 ore",
      bunker: {
        incorrectData: 'Attenzione: ci sono dati incoerenti',
        header: {
          LAST_BUK_CLEAN1: 'Ultimo ciclo pulizia 1',
          LAST_BUK_CLEAN2: 'Ultimo ciclo pulizia 2',
          LAST_BUK_CLEAN3: 'Ultimo ciclo pulizia 3',
          LAST_BUK_GATE_OPN1: 'Ultima apertura bunker 1',
          LAST_BUK_GATE_OPN2: 'Ultima apertura bunker 2',
          LAST_BUK_GATE_OPN3: 'Ultima apertura bunker 3',
        },
        data: {
          bar1_1: 'No pieno',
          bar1_2: 'Pieno',
          bar2_1: 'Inverter',
          bar2_2: 'Diretta',
          bar3_1: 'Manuale',
          bar3_2: 'Automatico',
          bar3_3: 'Locale',
          bar3_4: 'Sezionato',
          bar3_x: '--',
        },
      },
      general: 'Dati Generali',
      deviazione: {
        under: `Minore di {{defaultValue}}%`,
        over: 'Maggiore di {{defaultValue}}%',
      },
      workType: 'Tipologia lavoro',
      currentValue: 'Valore',
      mean: 'Media',
      reference: 'Rif.',
      limit: 'Limite',
      workshift: {
        start: 'Inizio turno',
        end: 'Fine turno',
        pause: 'Pausa',
        resume: 'Ripresa pausa',
        running: 'In corso',
        notEnded: 'Non terminato',
      },
      recipes: {
        1: 'mix',
        2: 'multi',
        3: 'mono',
        4: 'carta',
      },
      plantH: 'Durata turno',
      total: 'Totale',
      recipe: 'Ricetta',
      aspired: 'Aspirato',
      time: 'Tempo',
      bagBreaker: 'Rompisacco',
      setVelocita: 'Velocità',
      assorbimento: 'Corrente',
      assorbimentoMinimo: 'Min. Asso.',
      assorbimentoMassimo: 'Max. Asso',
      sforamentoAssorbimento: 'Sforamento Assorbimento',
      manAutoLocSec: 'Comando',
      avantiIndietro: 'Av/Ind',
      nopienoPieno: 'Pieno',
      marciaFermo: 'Mar/Ferm',
      inverterDiretta: 'Inv/Dir',
      manuale: 'Man',
      auto: 'Auto',
      avanti: 'Av',
      indietro: 'Ind',
      temperatura: 'Temperatura',
      performance: 'Performance',
      cicli: 'Cicli',
      locali: 'Loc',
      driveBypass: 'Alimentazione',
      localeSezionato: 'Loc/Sez',
      beltGraph: {
        manualeAutomatico: 'Modalità funzionamento',
      },
      bagGrap: {
        recipe: 'Modalità lavoro',
      },
      bagGraphMeasureDistributionBar: {
        low: '<= {{value}}%',
        mid: 'Marcia canonica',
        high: '>= {{value}}%',
        title: 'Distribuzione velocità',
        lowerEqualThen: '<=',
        higherEqualThen: '>=',
        percLimit: '{{symbol}} {{value}} %',
      },
      motorAbsorption: {
        max: 'Max {{value}} A',
        totaltTimeOverThresold: 'Tempo totale superamento',
        numberOfIntervalsOverThresold: 'N° intervalli',
        maximumDurationInterval: 'Intervallo più lungo',
        timeHHMM: '{{hh}} ore {{mm}} min',
        timeMMSS: '{{mm}} min {{ss}} sec',
      },
      ricetta: 'Ricetta',
      zonaAspirato: 'Zona Aspirato',
      duration: 'Durata',
      start: 'Inizio',
      pauses: 'Pause',
      rppGraph: {
        weight: 'Peso',
        total: 'Totale',
        tonHour: 't/h',
        ton: 't',
        averageWeightHour: 'Media Peso/ora (escluso CIT)',
        averageWeightHourSG2: 'Media Peso/ora',
        totalWeight: 'Totali Peso',
        totalBales: 'Totali balle',
        report: 'Report #{{num}}',
        numBales: 'Num. Balle',
        material: 'Materiale',
        keys: {
          'aluminium-reworked': 'ALLUMINIO rilavorato',
          'aluminium-super': 'ALLUMINIO super',
          'aluminium-to-rework': 'ALLUMINIO da rilavorare',
          'film-aggregate': 'FILM AGGREGATO (bunker insieme)',
          'film-manual': 'FILM MANUALE',
          'film-special': 'FILM SPECIAL',
          'flex-ballistic': 'FLEX BALISTICO',
          'flex-c': 'FLEX C',
          'flex-normal': 'FLEX S1',
          'flex-np': 'FLEX NP',
          'flex-sifted': 'FLEX VAGLIETTO',
          'flex-special': 'FLEX SPECIAL',
          'flex-tls': 'FLEX S2',
          'flex-x': 'FLEX X',
          'flex-xf': 'FLEX XF',
          'mpr-s': 'MPR-S',
          'plasmix-bulky': 'PLASMIX INGOMBRANTE',
          'plasmix-end-line': 'PLASMIX TERMINE LINEA',
          'plasmix-extra': 'PLASMIX AGGIUNTIVO',
          'plasmix-fine': 'PLASMIX FINE',
          'flex-s1': 'FLEX S1',
          'flex-s2': 'FLEX S2',
          mcpl: 'MCPL',
          mprc: 'MPR-C',
          mprs: 'MPR-S',
          casm: 'CAS/M',
          aluminium: 'Alluminio',
          cit: 'CIT',
          cpl: 'CPL',
          cta: 'CTA',
          ctc: 'CTC',
          cte: 'CTE',
          ctl: 'CTL',
          film: 'FILM',
          flex: 'FLEX',
          ipp: 'IPP',
          ips: 'IPS',
          iron: 'FERRO',
          mpoc: 'MPO/C',
          mpr: 'MPR',
          other: 'Altro',
          plasmix: 'PLASMIX',
          rpo: 'RPO',
          vpet: 'VPET',
          'alluminio-191203': 'Alluminio 191203',
          'ferro-191202': 'Ferro 191202',
          'notifica---191204': 'Notifica 191204',
          'sra---191204': 'SRA 191204',
          m219: 'M219',
          sra: 'SRA',
          'ba-flake': 'BA FLAKE',
          'ba-flake-hybrid': 'BA FLAKE HYBRID',
          'bp-ag': 'BP AG',
          'sra-mps': 'SRA MPS',
          'iron-u2': 'FERRO U2',
          'waste-191204': 'SCARTO - 191204',
          'waste-191212': 'SCARTO - 191212',
          'ba-class-a': 'BA CLASS A',
          'ba-class-b': 'BA CLASS B',
          'fondo-vasca-macinato': 'FONDO VASCA MACINATO',
          m207: 'M207',
        },
        valueProd: 'Prod. A VALORE',
      },
      power: 'Potenza motore',
      position: 'Posizione valvola',
      tempMelt: 'Temperatura Melt',
      torque: 'Coppia Motore',
    },
    options: {
      workshift: {
        undefined: '',
        1: 'Primo turno',
        2: 'Secondo turno',
        3: 'Terzo turno',
        99: 'Giornata',
      },
    },
    graph1: {
      title: 'Dati generali',
      subTitle: 'Coefficiente di resa',
      percManAutoLocSec: 'Percentuale comandi',
      percAspirated: 'Percentuale aspirato',
    },
    graph2: {
      title: 'Nastro trasportatore sotto inverter inversore',
      subTitle: 'Velocità - Assorbimento',
    },
    graph3: {
      title: 'Bunker',
      subTitle: '',
    },
    graph4: {
      title: 'Ventilatore',
      subTitle: '',
    },
    help: {
      brush: 'Scopri come muoverti sul grafico',
    },
    legendTitles: {
      manaut: 'MAN/AUTO',
      avind: 'AV/IND',
      aspi: 'ASPI',
      invdir: 'INV/DIR',
      nopienoPieno: 'PIENO',
      marciaFermo: 'MAR/FERM',
      manualeAutomatico: 'COMANDO',
      recipeNum: 'MODALITÀ DI LAVORO',
      recipe: 'RICETTA',
      driveBypass: 'ALIMENTAZIONE',
      localeSezionato: 'LOC/SEC',
      globalRicetta: 'RICETTA',
      zonaAspirato: 'ZONA ASPIRATO',
      manAutoLocSec: 'COMANDO',
      running: 'IN FUNZIONE',
      command: 'COMANDO',
      program: 'PROGRAMMA',
      sync: 'SINCRONIZZAZIONE',
      valvePosition: 'POSIZIONE VALVOLA',
      direction: "AV/IND",
      key1Inserted: 'CHIAVE INSERITA',
      key2Inserted: 'CHIAVE INSERITA',
      key3Inserted: 'CHIAVE INSERITA',
      key4Inserted: 'CHIAVE INSERITA',
      key5Inserted: 'CHIAVE INSERITA',
      key6Inserted: 'CHIAVE INSERITA',
      key7Inserted: 'CHIAVE INSERITA',
      key8Inserted: 'CHIAVE INSERITA',
      key9Inserted: 'CHIAVE INSERITA',
      key10Inserted: 'CHIAVE INSERITA',
      key11Inserted: 'CHIAVE INSERITA',
      key12Inserted: 'CHIAVE INSERITA',
      key13Inserted: 'CHIAVE INSERITA',
      key14Inserted: 'CHIAVE INSERITA',
      bufferLoadedWithF015: 'BUFFER IN CARICAMENTO',
      bufferLoadedWithF070: 'BUFFER IN CARICAMENTO',
      densifierBeltState: "STATO NASTRO",
      decOPMode: "MODALITA OPERATIVA",
    },
    lavorazioneAttuale: {
      1: 'CIT',
      2: 'FLUSSO C',
      3: 'ALLUMINIO',
      4: 'RILAVORAZIONE',
      5: 'ALTRO/PROVE',
    },
    ricettaAttuale: {
      default: 'Ricetta ',
      1: 'CIT NORMALE',
      2: 'CIT SENZA BUNKER M360',
      3: 'FLUSSO C',
      4: 'ALLUMINIO',
      5: '300 INTASATO',
      6: '318 INTASATO',
      7: '321 INTASATO',
      8: '345 INTASATO',
      9: 'RIPASSO MPO',
      10: 'CIT NORMALE',
      11: 'CIT SENZA FINE',
      12: '327 INTASATO',
      13: 'PULIZIA',
    },
    legend: {
      command: {
        0: 'Manuale',
        1: 'Automatico',
        2: 'Locale',
        3: 'Sezionato',
      },
      running: {
        0: 'Fermo',
        1: 'In funzione',
      },
      program: {
        1: 'P1',
        2: 'P2',
        3: 'P3',
        4: 'P4',
      },
      sync: {
        0: 'OFF',
        1: 'ON',
      },
      valvePosition: {
        0: 'APERTA',
        1: 'CHIUSA',
      },
      densifierBeltState: {
        0:"SPENTO",
        1:"ACCESO",
        2:"ALLARME",
        3:"SEZIONATO",
        4:"PRONTO"
      },
      bufferLoadedWithF015: {
        0: 'Nessuno',
        1: 'Buffer 1',
        2: 'Buffer 2',
        3: 'Buffer 3',
        4: 'Buffer 4',
        5: 'Buffer 5',
      },
      bufferLoadedWithF070: {
        1: 'Buffer 1',
        2: 'Buffer 2',
        3: 'Buffer 3',
        4: 'Buffer 4',
        5: 'Buffer 5',
      },
      manaut: {
        0: 'AUT',
        1: 'MAN',
      },
      manAutoLocSec: {
        0: 'Manuale',
        1: 'Automatico',
        2: 'Locale',
        3: 'Sezionato',
        undefined: 'Non definito',
        sec: 'Sezionato',
        loc: 'Locale',
        auto: 'Automatico',
        man: 'Manuale',
      },
      decOPMode: {
        0: 'IN LOCALE',
        1: 'DA REMOTO',
      },
      direction: {
        0: 'INDIETRO',
        1: 'AVANTI',
      },
      avind: {
        0: 'INDIETRO',
        1: 'AVANTI',
      },
      deviazione: {
        under: 'MINORE DI ',
        over: 'MAGGIORE DI ',
      },
      aspi: {
        SI: 'SI',
        NO: 'NO',
      },
      invdir: {
        0: 'INVERTER',
        1: 'DIRETTA',
      },
      nopienoPieno: {
        0: 'NO PIENO',
        1: 'PIENO',
      },
      marciaFermo: {
        0: 'ARRESTO',
        1: 'MARCIA',
      },
      manualeAutomatico: {
        0: 'MAN',
        1: 'AUTO',
      },
      recipeNum: {
        1: 'mix',
        2: 'multi',
        3: 'mono',
        4: 'carta',
        5: 5,
        10: 10,
      },
      globalRicetta: {
        0: '0',
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
        7: '7',
        8: '8',
        9: '9',
        10: '10',
        11: '11',
        12: '12',
        13: '13',
      },
      driveBypass: {
        0: 'DRIVE',
        1: 'BY-PASS',
      },
      localeSezionato: {
        0: 'locale',
        1: 'sezionato',
      },
      zonaAspirato: {
        0: 'disabilitata',
        1: 'abilitata',
      },
      workshift: 'Workshift',
      aspiredZone: {
        BeltOnAspOnPerc: 'Nastro e ventilatore attivi',
        BeltOnAspOffPerc: 'Nastro acceso e ventilatore spento',
        BeltOffPerc: 'Nastro spento',
      },
      plantHaltsTimes: {
        0: 'Impianto spento',
        1: 'Impianto acceso',
        2: 'Cambio materiale',
        10: 'Carta',
        11: 'Plastica',
        12: 'Multimateriale',
        13: 'Alluminio',
        14: 'PET',
        15: 'Plastica CIT',
        16: 'Ferro',
        17: 'Plastica Qualità',
        18: 'Tetrapack',
      },
      key1Inserted: {
        0: 'NO',
        1: 'SI',
      },
      key2Inserted: {
        0: 'NO',
        1: 'SI',
      },
      key3Inserted: {
        0: 'NO',
        1: 'SI',
      },
      key4Inserted: {
        0: 'NO',
        1: 'SI',
      },
      key5Inserted: {
        0: 'NO',
        1: 'SI',
      },
      key6Inserted: {
        0: 'NO',
        1: 'SI',
      },
      key7Inserted: {
        0: 'NO',
        1: 'SI',
      },
      key8Inserted: {
        0: 'NO',
        1: 'SI',
      },
      key9Inserted: {
        0: 'NO',
        1: 'SI',
      },
      key10Inserted: {
        0: 'NO',
        1: 'SI',
      },
      key11Inserted: {
        0: 'NO',
        1: 'SI',
      },
      key12Inserted: {
        0: 'NO',
        1: 'SI',
      },
      key13Inserted: {
        0: 'NO',
        1: 'SI',
      },
      key14Inserted: {
        0: 'NO',
        1: 'SI',
      },
    },
    ventilator: {
      lines: {
        line1: 'Linea 1',
        line2: 'Linea 2',
        line5: 'Linea 5',
      },
    },
    plantStatus: {
      title: 'STATO IMPIANTO',
      productivityManagement: 'GESTIONE CARICAMENTO',
      smokeManagement: 'GESTIONE FUMI',
      efficiency: 'EFFICIENZA',
      vecoplan1ActiveStatus: 'F001',
      fan316ActiveStatus: 'F316',
      line1ActiveStatus: 'Linea 1',
      line2ActiveStatus: 'Linea 2',
      line3ActiveStatus: 'Linea 5',
      scrubber1ActiveStatus: 'F303',
      scrubber2ActiveStatus: 'F304',
      fanF314ActiveStatus: 'F314',
      fanF306ActiveStatus: 'F306',
    },
    untha: {
      generalGraph: {
        title: "Stato Untha"
      }
    },
    vecoplant: {
      title: 'Vecoplant',
      generalGraph: {
        title: 'Stato vecoplan',
      },
      vecoplanTable: {
        number: 'Numero di fermi',
        time: 'Tempo di fermo',
        averageSpeed: 'Media velocità (escluso lo zero)',
        averageThrustPresser: 'Media spinta pressore (escluso lo zero)',
        reverseCountActualNumber: 'Numero inversioni',
        alarmCount: 'Numero inversioni',
      },
      graph1: {
        title: 'Apertura Impianto - Riepilogo Fermi',
      },
      bufferFill: {
        table: {
          time: 'Tempo sotto soglia',
        },
      },
      bufferState: {
        loadable: 'Disponibile al carico',
        notLoadable: 'Non caricabile',
        emptying: 'Svuotamento',
        buffers: {
          buffer_f017: 'Buffer 1',
          buffer_f026: 'Buffer 2',
          buffer_f055: 'Buffer 5',
          buffer1: 'Buffer 1',
          buffer2: 'Buffer 2',
          buffer5: 'Buffer 5',
        },
      },
      bufferStateTitle: 'Stato Buffer',
      bufferFillTitle: 'Riempimento Buffer',
      buffer1FillTitle: 'Riempimento Buffer 1',
      buffer2FillTitle: 'Riempimento Buffer 2',
      buffer3FillTitle: 'Riempimento Buffer 3',
      buffer4FillTitle: 'Riempimento Buffer 4',
      buffer5FillTitle: 'Riempimento Buffer 5',
      averageDistributionTitle: 'Set velocità',
      averageDistribution: {
        zeroToThirty: '0 - 30%',
        thirtyOneToForty: '31 - 40%',
        fortyOneToFifty: '41 - 50%',
        fiftyOneToSixty: '51 - 60%',
        sixtyOneToSeventy: '61 - 70%',
        seventyTo100: '71 - 100%',
      },
      chartLabel: {
        timeUnderMinThreshold: 'tempo lav.\nsotto il minimo',
        timeOverMinThreshold: 'tempo lav.\nsopra il massimo',
      },
    },
    scrubber: {
      trendLiterHour: 'Spurgo l/h',
      timeThreshold: 'Tempo soglia',
      activeDeactivate: 'Stato',
      literPerHourTrend: {
        valve2Thr: 'Soglia valvola 2',
        valve2: 'Valvola 2',
        valve1Thr: 'Soglia valvola 1',
        valve1: 'Valvola 1',
        total: 'Totale litri: ',
        average: 'Media litri: ',
      },
      v: {
        over: 'Sopra soglia',
        under: 'Sotto soglia',
        scrubbers: {
          f305_flw01: 'Scrubber 1',
          f305_flw02: 'Scrubber 2',
          scrubber1: 'Scrubber 1',
          scrubber2: 'Scrubber 2',
        },
      },
      scrubberThrTime: {
        scrubbers: {
          scrubber1: 'Scrubber 1',
          scrubber2: 'Scrubber 2',
        },
        under: 'Sotto soglia',
        over: 'Sopra soglia',
      },
      scrubberStatus: {
        active: 'Attivo',
        inactive: 'Disattivo',
        activeTime: 'Attivo: ',
        inactiveTime: 'Disattivo: ',
        aboveTime: 'Sopra soglia: ',
        underTime: 'Sotto soglia: ',
        scrubbers: {
          scrubber1: 'Scrubber F303',
          scrubber2: 'Scrubber F304',
        },
      },
    },
    lines: {
      linesStatusTitle: 'Stato linee',
      loadingBeltTitle: 'Nastro di carico',
      engineSpeedDensifierTitle: 'Velocità motore densificatore',
      engineTorqueDensifierTitle: 'Coppia motore densificatore',
      waterDrainValveTitle: 'Acqua di raffreddamento estrusione',
      millsTitle: 'Mulini',
      densifierTorqueStatus: {
        dead: "Coppia dens. < 25%",
        warning: "Coppia dens. tra il 25% e 78% (>10 min)",
        running: "Coppia dens. > 78%"
      },
      millAbsorptionState: {
        aboveFirstThreshold: 'Assorb. mulino > soglia 1',
        belowFirstThreshold: 'Assorb. mulino < soglia 1',
      },
      mills: {
        table: {
          overValue: 'Superamenti',
          thr1: 'Soglia 1',
          thr2: 'Soglia 2',
          thr3: 'Soglia 3',
          overTimeValue: 'Tempo',
        },
        mill1: 'Mulino 1',
        mill2: 'Mulino 2',
        mill3: 'Mulino 3',
        mill4: 'Mulino 4',
        label: {
          mill1Thr1: 'Soglia 1',
          mill2Thr1: 'Soglia 1',
          mill3Thr1: 'Soglia 1',
          mill4Thr1: 'Soglia 1',
          mill1Thr2: 'Soglia 2',
          mill2Thr2: 'Soglia 2',
          mill3Thr2: 'Soglia 2',
          mill4Thr2: 'Soglia 2',
          mill1Thr3: 'Soglia 3',
          mill2Thr3: 'Soglia 3',
          mill3Thr3: 'Soglia 3',
          mill4Thr3: 'Soglia 3',
          mill1Absorption: 'Assorbimento',
          mill2Absorption: 'Assorbimento',
          mill3Absorption: 'Assorbimento',
          mill4Absorption: 'Assorbimento',
        },
      },
      waterDrainValve: {
        averageTrend: 'Media andamento l/h',
        labels: {
          valve1: 'Valvola 1',
          valve2: 'Valvola 2',
        },
        valves: {
          f402: 'Valvola 1',
          f403: 'Valvola 2',
          f305_flw01: 'Valvola 1',
          f305_flw02: 'Valvola 2',
        },
      },
      engineTorqueDensifier: {
        averageTorque: 'Coppia media',
        secondsAtZero: 'Tempo coppia a zero (<25%)',
        secondsNotAtZero: 'Tempo in coppia (>68%)',
        totalSeconds: 'Tempo totale',
        excludeZero: '(escluso lo zero)',
        rangeUnder68: '<68% (escluso lo zero)',
        rangeFrom68To78: '68 - 78%',
        rangeFrom78to88: '78 - 88%',
        rangeOver88: '+88%',
        densifierUptime: 'Tempo acceso',
        engines: {
          dec_f019: 'Linea 1',
          dec_f028: 'Linea 2',
          dec_f057: 'Linea 5',
          dec_f076: 'Linea 3',
          dec_f086: 'Linea 4',
        },
      },
      engineSpeedDensifier: {
        countHighlight: {
          3: 'Eventi compresi tra 3 e 10 minuti',
          10: 'Eventi maggiori di 10 min',
          total: 'Eventi totali (maggiori di 3 min.)',
        },
        averageTorque: 'Coppia media',
        secondsAtZero: {
          engine1: 'Tempo coppia a zero ',
          engine2: 'Tempo coppia a zero ',
          engine3: 'Tempo coppia a zero ',
          engine4: 'Tempo coppia a zero ',
          engine5: 'Tempo coppia a zero ',
        },
        engines: {
          engine1: 'Linea 1',
          engine2: 'Linea 2',
          engine3: 'Linea 3',
          engine4: 'Linea 4',
          engine5: 'Linea 5',
        },
        label: {
          line1Values: 'Coppia',
          line1SpeedValues: 'Giri viti',
          line1Threshold: 'Soglia coppia',
          line2Values: 'Coppia',
          line2SpeedValues: 'Giri viti',
          line2Threshold: 'Soglia coppia',
          line3Values: 'Coppia',
          line3SpeedValues: 'Giri viti',
          line3Threshold: 'Soglia coppia',
          line4Values: 'Coppia',
          line4SpeedValues: 'Giri viti',
          line4Threshold: 'Soglia coppia',
          line5Values: 'Coppia',
          line5SpeedValues: 'Giri viti',
          line5Threshold: 'Soglia coppia',
        },
        overThr: {
          engine1: '% Superamento soglia coppia',
          engine2: '% Superamento soglia coppia',
          engine3: '% Superamento soglia coppia',
          engine4: '% Superamento soglia coppia',
          engine5: '% Superamento soglia coppia',
        },
        overThrNumber: {
          engine1: 'Numero superamenti soglia',
          engine2: 'Numero superamenti soglia',
          engine3: 'Numero superamenti soglia',
          engine4: 'Numero superamenti soglia',
          engine5: 'Numero superamenti soglia',
        },
      },
      linesBuffer: {
        buffers: {
          buffer_f019: 'Linea 1',
          buffer_f028: 'Linea 2',
          buffer_f057: 'Linea 5',
          buffer_f076: 'Linea 3',
          buffer_f086: 'Linea 4',
          buffer1: 'Linea 1',
          buffer2: 'Linea 2',
          buffer5: 'Linea 5',
          buffer3: 'Linea 3',
          buffer4: 'Linea 4',
        },
      },
      loadingBelt: {
        buffer1: 'Linea 1',
        buffer2: 'Linea 2',
        buffer5: 'Linea 5',
        excludeZero: '(escluso lo zero)',
        belts: {
          buffer_f019: 'Linea 1',
          buffer_f028: 'Linea 2',
          buffer_f057: 'Linea 5',
          buffer_f076: 'Linea 3',
          buffer_f086: 'Linea 4',
          timeAtZero: 'Tempo rimasto a zero',
          averageTorque: 'Velocità media',
        },
      },
      linesStatus: {
        buffers: {
          buffer1: 'Linea 1',
          buffer2: 'Linea 2',
          buffer5: 'Linea 5',
          buffer3: 'Linea 3',
          buffer4: 'Linea 4',
        },
        active: 'Linea attiva',
        inactive: 'Linea disattiva',
        empty: 'Buffer vuoto',
        full: 'Buffer pieno',
        noData: 'Nessun dato',
      },
    },
    halts: {
      title: 'Fermi',
      pie: {
        vPlantTimeReportLine01: 'Linea 1',
        vPlantTimeReportLine02: 'Linea 2',
        vPlantTimeReportLine03: 'Linea 3',
        vPlantTimeReportLine04: 'Linea 4',
        vPlantTimeReportLine05: 'Linea 5',
        vBulkyStopImpactLine01: 'Linea 1',
        vBulkyStopImpactLine02: 'Linea 2',
        vBulkyStopImpactLine03: 'Linea 3',
        vBulkyStopImpactLine04: 'Linea 4',
        vBulkyStopImpactLine05: 'Linea 5',
        vBulkyStopsLine01: 'Linea 1',
        vBulkyStopsLine02: 'Linea 2',
        vBulkyStopsLine03: 'Linea 3',
        vBulkyStopsLine04: 'Linea 4',
        vBulkyStopsLine05: 'Linea 5',
        vHaltCausesLine01: 'Linea 1',
        vHaltCausesLine02: 'Linea 2',
        vHaltCausesLine03: 'Linea 3',
        vHaltCausesLine04: 'Linea 4',
        vHaltCausesLine05: 'Linea 5',
        vManualStopsLine01: 'Linea 1',
        vManualStopsLine02: 'Linea 2',
        vManualStopsLine03: 'Linea 3',
        vManualStopsLine04: 'Linea 4',
        vManualStopsLine05: 'Linea 5',
        vOpenGatesLine01: 'Linea 1',
        vOpenGatesLine02: 'Linea 2',
        vOpenGatesLine03: 'Linea 3',
        vOpenGatesLine04: 'Linea 4',
        vOpenGatesLine05: 'Linea 5',
        vUnjustifiedStopsLine01: 'Linea 1',
        vUnjustifiedStopsLine02: 'Linea 2',
        vUnjustifiedStopsLine03: 'Linea 3',
        vUnjustifiedStopsLine04: 'Linea 4',
        vUnjustifiedStopsLine05: 'Linea 5',
        vAssetsCausingCloggingLine01: 'Linea 1',
        vAssetsCausingCloggingLine02: 'Linea 2',
        vAssetsCausingCloggingLine03: 'Linea 3',
        vAssetsCausingCloggingLine04: 'Linea 4',
        vAssetsCausingCloggingLine05: 'Linea 5',
        vJustifiedByLine01: 'Linea 1',
        vJustifiedByLine02: 'Linea 2',
        vJustifiedByLine03: 'Linea 3',
        vJustifiedByLine04: 'Linea 4',
        vJustifiedByLine05: 'Linea 5',
      },
      labels: {
        events: 'Eventi',
        time: 'Tempo',
      },
      plantTimeReport: {
        title: 'Apertura impianto Riepilogo Fermi',
        titleShift: 'Riepilogo Fermi',
        legends: {
          plantRunning: 'Impianto in funzione',
          haltOtherCauses: 'Fermo altre cause',
          haltBulky: 'Fermo ingombranti',
          downStreamLine: 'Linea a valle',
          manualStops: 'Svuotamento',
          totalWorkingTime: 'Totale tempo lavorato',
          standardWorkingTime: 'Tempo impianto standard',
        },
      },
      dailyUnjustifiedStops: {
        title: 'Impatto fermi da motivare',
        labels: {
          unjustified: 'Fermi da motivare',
          other: 'Altri fermi',
        },
      },
      dailyJustifiedBy: {
        title: 'Compilazione motivazioni',
        labels: {
          justified_by_users: 'Fermi motivati',
          justified_by_system: 'Fermi non motivati',
        },
      },
      bulkyStopImpact: {
        title: 'Impatto fermi ingombranti',
        lables: {
          haltOtherCauses: 'Fermo altre cause',
          haltBulky: 'Fermo ingombranti',
          haltTotalTime: 'Tempo totale fermi',
        },
      },
      haltCauses: {
        title: 'Cause di fermo',
        titles: {
          Line01: 'Cause di fermo (Linea 1)',
          Line02: 'Cause di fermo (Linea 2)',
          Line05: 'Cause di fermo (Linea 5)',
          Load: 'Cause di fermo',
        },
      },
      bulkyStops: {
        title: 'Dettagli causa fermo: Fermo ingombranti',
        titles: {
          Line01: 'Dettagli causa fermo: Fermo ingombranti (Linea 1)',
          Line02: 'Dettagli causa fermo: Fermo ingombranti (Linea 2)',
          Line05: 'Dettagli causa fermo: Fermo ingombranti (Linea 5)',
          Load: 'Dettagli causa fermo: Fermo ingombranti',
        },
      },
      openGates: {
        title: 'Dettagli causa fermo: Cancello aperto',
        titles: {
          Line01: 'Dettagli causa fermo: Cancello aperto (Linea 1)',
          Line02: 'Dettagli causa fermo: Cancello aperto (Linea 2)',
          Line05: 'Dettagli causa fermo: Cancello aperto (Linea 5)',
          Load: 'Dettagli causa fermo: Cancello aperto',
        },
      },
      manualStops: {
        title: 'Dettagli causa fermo: Arresto manuale',
        titles: {
          Line01: 'Dettagli causa fermo: Arresto manuale (Linea 1)',
          Line02: 'Dettagli causa fermo: Arresto manuale (Linea 2)',
          Line05: 'Dettagli causa fermo: Arresto manuale (Linea 5)',
          Load: 'Dettagli causa fermo: Arresto manuale',
        },
      },
      assetsCausingClogging: {
        title: 'Cespiti che hanno causato intasamento',
        titles: {
          Line01: 'Cespiti che hanno causato intasamento (Linea 1)',
          Line02: 'Cespiti che hanno causato intasamento (Linea 2)',
          Line05: 'Cespiti che hanno causato intasamento (Linea 5)',
          Load: 'Cespiti che hanno causato intasamento',
        },
      },
      processing: {
        general: 'Totale',
        '10': 'Carta',
        '11': 'Plastica',
        '12': 'Multimateriale',
        '13': 'Alluminio',
        '14': 'PET',
        '15': 'Plastica CIT',
        '16': 'Ferro',
        '17': 'Plastica Qualità',
        '18': 'Tetrapack',
      },
      chartLabels: {
        Alarm: 'Anomalia',
        bulky: 'Fermo ingombranti',
        Button: 'Pulsante',
        Clog: 'Intasamento',
        Emergency: 'Emergenza',
        Empty: 'Svuotamento impianto',
        empty: 'Svuotamento',
        ManDevStp: 'Utenza',
        ManHmiPause: 'Ingombranti',
        ManHmiStp: 'Supervisore',
        ManLoadRec: 'Cambio ricetta',
        ManOther: 'Non classificato',
        ManSelAuto: 'Ruotato selettore man/auto su RCB2',
        ManSelClean: 'Pulizia',
        ManSftyLock: 'Manca chiave',
        ManStopOther: 'Altro',
        ManualStop: 'Arresto manuale',
        None: 'Non classificato',
        OpenGate: 'Cancello aperto',
        other: 'Fermo altre cause',
        Pause: 'Fermi Ingombranti',
        plantStandardWorkingTime: 'Tempo impianto standard',
        runningTime: 'Impianto in funzione',
        totalHaltsTime: 'Totale tempo fermi',
        totalWorkingTime: 'Totale tempo lavorato',
        Unhandled: 'Causa non definita',
        morning_delay: 'Ritardo Avvio',
        pause_delay: 'Ritardo Dopo Pausa',
        BunkerFull: 'Bunker pieno',
        DelayedStart: 'Partenza Ritardata',
        PaidBreak: 'Pausa Retribuita',
        unjustified: 'Fermi da motivare',
        DownStreamLine: 'Linea a valle',
        downStreamLine: 'Linea a valle',
        MaintenanceStop: 'Fermo manutentivo',
        FullyDelay: 'Troppo pieno ritardato',
        LineDisabled: 'Linea disabilitata',
      },
      chartLabelsTooltip: {
        ManDevStp: 'Utenza specifica',
        ManHmiPause: 'Fermo da pannello ingombranti',
        ManHmiStp: 'Fermo da supervisore',
        ManLoadRec: 'Cambio ricetta',
        ManOther: 'Non classificato',
        ManSelAuto: 'Ruotato selettore man/auto su RCB2',
        ManSelClean: 'Fermo pulizia',
        ManSftyLock: 'Chiave sicurezza assente',
        ManStopOther: 'Altro',
        ManualStop: 'Arresto manuale',
        None: 'Non classificato',
        OpenGate: 'Cancello aperto',
        other: 'Fermo altre cause',
      },
    },
    multiLineChartsLabels: {
      trend: 'Andamento',
      MAIN_TORQUE: 'COPPIA',
      PISTON_POS_1: 'PISTONE',
      SCND_LIMIT_TORQUE_ALARM: 'DOSATORE',
      TEMP_MELT: 'TEMPERATURE MELT',
      BELT_SPD: 'DOSATORE',
      mean: {
        MAIN_TORQUE: 'Coppia media turno',
        TEMP_MELT: 'Temperatura media melt',
        BELT_SPD: 'Velocità media dosatore',
        PISTON_POS_1: 'Apertura media pistone',
      },
      footerLabels: {
        alarmsSecondLimit: 'ALLARMI 2a SOGLIA',
        mainTorqueZero: 'FERMI (COPPIA 0)',
        stopCount: 'Num. FERMI',
        stopDuration: 'Durata FERMI',
        noData: 'n.d.',
      },
    },
    titech: {
      problems: '{{count}} problema',
      problems_plural: '{{count}} problemi',
      parameterChanges: '{{count}} cambi parametri',
      lastRecipe: 'Ultima ricetta impostata:',
      lastCheck: 'Ultimo controllo riuscito:',
      mostUsedRecipe: 'più usata ({{count}}%)',
      calibration: {
        status: {
          calibrated: 'TARATA',
          expired: 'TARATURA SCADUTA',
          calibrationToday: 'DA TARARE OGGI',
          lastCheckNull: 'NON RILEVATA',
        },
      },
      beltOnTitechOff: 'Nastro accesso e titech ferma',
      beltOff: 'Nastro spento',
      events: 'Eventi:',
      totalTime: 'Tempo totale:',
    },
    bearings: {
      title: 'Temperatura Cuscinetti',
      noWarnings: 'Non ci sono Cuscinetti in stato di allarme',
      threshold: 'Soglia',
      warning: 'allerta',
      stop: 'fermo',
      intervalsNumber: 'N° intervalli',
      maxIntervalDuration: 'Intervallo più lungo',
      totalTime: 'Tempo totale',
    },
  },
  dateRangePicker: {
    labels: {
      yesterday: 'Ieri',
      week: 'Settimana',
      month: 'Mese',
    },
  },
}

export default it
