const COLORS = {
  activeStatus: {
    0: '#E26326',
    1: '#88C94D',
  },
  densifierTorqueStatus: {
    0: '#FED283',
    1: '#3f7f30',
    2: '#F55A62',
  },
  fillStatus: {
    0: '#7B17D9',
    1: '#53D2F9',
  },
  bufferState: {
    0: '#FED283',
    1: '#3f7f30',
    2: '#F55A62',
  },
  millAbsorptionState: {
    0: '#FED283',
    1: '#3f7f30',
  },
}

export { COLORS }
