import { Flex } from '@mv-submodules/mvlabs-components-fe/ui/components'
import { Fit } from '@mv-submodules/mvlabs-components-fe/ui/components/Flex/Flex'
import { TFunction } from 'i18next'
import React from 'react'
import { COLORS } from '../_colors'

interface Props {
  t: TFunction
}

const BufferLineChartLegend = ({ t }: Props) => {
  return (
    <Flex fit={Fit.multiLine}>
      <Flex spaceSize="tiny">
        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
          1.
        </span>
        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
          <span className="label-color-square" style={{ backgroundColor: COLORS.activeStatus[1] }} />
          <span>{t('plantAnalysis.lines.linesStatus.active')}</span>
        </span>
        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
          <span className="label-color-square" style={{ backgroundColor: COLORS.activeStatus[0] }} />
          <span>{t('plantAnalysis.lines.linesStatus.inactive')}</span>
        </span>
      </Flex>
      <Flex spaceSize="tiny">
        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
          2.
        </span>
        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
          <span className="label-color-square" style={{ backgroundColor: COLORS.densifierTorqueStatus[2] }} />
          <span>{t('plantAnalysis.lines.densifierTorqueStatus.dead')}</span>
        </span>
        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
          <span className="label-color-square" style={{ backgroundColor: COLORS.densifierTorqueStatus[0] }} />
          <span>{t('plantAnalysis.lines.densifierTorqueStatus.warning')}</span>
        </span>
        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
          <span className="label-color-square" style={{ backgroundColor: COLORS.densifierTorqueStatus[1] }} />
          <span>{t('plantAnalysis.lines.densifierTorqueStatus.running')}</span>
        </span>
      </Flex>
      <Flex spaceSize="tiny">
        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
          3.
        </span>
        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
          <span className="label-color-square" style={{ backgroundColor: COLORS.fillStatus[0] }} />
          <span>{t('plantAnalysis.lines.linesStatus.empty')}</span>
        </span>
        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
          <span className="label-color-square" style={{ backgroundColor: COLORS.fillStatus[1] }} />
          <span>{t('plantAnalysis.lines.linesStatus.full')}</span>
        </span>
      </Flex>
      <Flex spaceSize="tiny">
        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
          4.
        </span>
        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
          <span className="label-color-square" style={{ backgroundColor: COLORS.bufferState[1] }} />
          <span>{t('plantAnalysis.vecoplant.bufferState.loadable')}</span>
        </span>
        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
          <span className="label-color-square" style={{ backgroundColor: COLORS.bufferState[2] }} />
          <span>{t('plantAnalysis.vecoplant.bufferState.notLoadable')}</span>
        </span>
        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
          <span className="label-color-square" style={{ backgroundColor: COLORS.bufferState[0] }} />
          <span>{t('plantAnalysis.vecoplant.bufferState.emptying')}</span>
        </span>
      </Flex>
      <Flex spaceSize="tiny">
        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
          5.
        </span>
        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
          <span className="label-color-square" style={{ backgroundColor: COLORS.millAbsorptionState[1] }} />
          <span>{t('plantAnalysis.lines.millAbsorptionState.belowFirstThreshold')}</span>
        </span>
        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
          <span className="label-color-square" style={{ backgroundColor: COLORS.millAbsorptionState[0] }} />
          <span>{t('plantAnalysis.lines.millAbsorptionState.aboveFirstThreshold')}</span>
        </span>
      </Flex>
    </Flex>
  )
}

export default BufferLineChartLegend
