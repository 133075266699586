import * as React from 'react'
import { Line } from 'recharts'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { DateRangeProps, Workshift } from '../../../../types/workshift'
import { Measure } from '../../../../types/measure'
import { API } from '../../../../redux/actions'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { Loader } from '../../../../functions/shared'
import BottomLegend from './_graphs/BottomLegend/GenericBottomLegend'
import GraphTimelineView from '../GraphTimeline/GraphTimelineView'
import GraphBrush from '../GraphBrush/GraphBrush'
import GenericLineGraph from './_graphs/LineGraph/GenericLineGraph'
import { debounce } from '../../../../../inplant-components-fe/mvfunctions/helpers'
import { AdditionalSupportedMeasures, supportedMeasuresByWord, WordSupportedMeasures } from './_measures/_measures'
import { consoleLog } from '@mv-submodules/mvlabs-components-fe/functions/logs'
import {
  BaseWordMeasures,
  binaryFieldsForWordMeasure,
  derivedWordMeasures,
  DerivedWordMeasures,
  getWordValueFromBinaryMapping,
  SupportedWordsSlugs,
} from './_measures/_bynaryMappings'
import {
  parseResponseData,
  parseResponseSingleData,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/series'
import { SingleDataValue } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/singleDataValue'
import { hydrateData, WarnAreasData } from './utils/hydrate'
import GenericTimeBarGraph from './_graphs/TimeBarGraph/GenericTimeBarGraph'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'

const measureRepresentation = ['time-bar', 'line'] as const
type MeasureRepresentation = typeof measureRepresentation[number]

interface MeasureToShow {
  /** Override assetId for fetching data (default {@link OwnProps.id}) */
  assetId?: string
  name: AdditionalSupportedMeasures
  slug: string
  unitOfMeasure?: string
  representation: MeasureRepresentation
  /** Override measure color (default sequentially generated) */
  color?: string
  /** Override measure label (default {@link MeasureToShow.name}) */
  label?: string
}

interface WordToShow {
   /** Override assetId (default {@link OwnProps.id}) */
  assetId?: string
  name: string
  type: SupportedWordsSlugs
}

interface OwnState {
  wordsRawData: { [key: string]: Measure }
  wordsRawDataLast: { [key: string]: Measure }
  data: { [key: string]: Measure }
  dayEnd: string | null
  dayStart: string | null
  endDate: string | number
  fetchErrors: boolean
  filteredData: any[]
  isCollapsed: boolean
  isLoading: boolean
  last: any
  preparedData: undefined | any[]
  startDate: string | number
  brushStartIndex: number
  brushEndIndex: number
}

interface OwnProps {
  active: string
  id: string
  toggleCollapse: (id: string) => void
  title?: string
  measures?: MeasureToShow[]
  binaryWords?: WordToShow[]
  warnAreas?: WarnAreasData[]
}

interface StateProps {
  plant: string | null
  workshift: Workshift
}

const mapStateToProps = (state: any): StateProps & DateRangeProps => ({
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  days: state.plantAnalysis.common.days,
  isDateFilterRange: state.plantAnalysis.common.isDateFilterRange,
  plant: state.plantSelector.plant,
  workshift: state.plantAnalysis.common.workshift,
})

type Props = StateProps & OwnProps & WithTranslation & DateRangeProps

function initState(measures: MeasureToShow[] = [], words: WordToShow[] = []) {
  const additionalMeasureData = (measures ?? []).reduce(
    (acc, measure) => ({
      ...acc,
      [measure.name]: {
        data: [],
        min: 0,
        max: 0,
      },
    }),
    {}
  )

  const wordsData = words.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.name]: {
        data: [],
        min: 0,
        max: 0,
      },
    }),
    {}
  )

  return {
    data: {
      ...additionalMeasureData,
    },
    brushStartIndex: 0,
    brushEndIndex: 0,
    last: null,
    wordsRawData: { ...wordsData },
    wordsRawDataLast: {},
    dayEnd: null,
    dayStart: null,
    endDate: 'auto',
    fetchErrors: false,
    filteredData: [],
    isCollapsed: false,
    isLoading: false,
    preparedData: [],
    startDate: 'auto',
  }
}

class GeneralBinarySrcGraph extends React.Component<Props, OwnState> {
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)
    this.state = initState(props.measures ?? [], props.binaryWords)
    this.exportData = this.exportData.bind(this)
    this.handleBrush = debounce(this.handleBrush.bind(this), 10)
  }

  public componentDidMount() {
    const date = moment.isMoment(this.props.dateFilterStart)
      ? this.props.dateFilterStart
      : moment(this.props.dateFilterStart)
    if (this.props.dateFilterStart && moment.isMoment(date) && this.props.workshift) {
      this.getData(this.props)
    }
  }

  public componentWillUnmount() {
    this.setState(initState(this.props.measures, this.props.binaryWords))
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const date = moment.isMoment(nextProps.dateFilterStart)
      ? nextProps.dateFilterStart
      : moment(nextProps.dateFilterStart)
    if (
      (this.props.dateFilterStart !== nextProps.dateFilterStart ||
        this.props.workshift !== nextProps.workshift ||
        nextProps.active === this.props.id) &&
      nextProps.dateFilterStart &&
      moment.isMoment(date) &&
      nextProps.workshift
    ) {
      this.getData(nextProps)
    } else {
      if (this.state.preparedData && this.state.preparedData?.length > 0) {
        return
      }
      const cleanStateWithouFetch = (({ isLoading, fetchErrors, ...others }) => ({ ...others }))(
        initState(this.props.measures, this.props.binaryWords)
      )
      this.setState(cleanStateWithouFetch)
    }
  }

  public render() {
    const isReady = this.state.preparedData !== undefined && this.state.preparedData.length > 0 && !this.state.isLoading
    const noData =
      this.state.preparedData !== undefined && this.state.preparedData.length === 0 && !this.state.isLoading

    const measuresRepresentedByLine = (this.props.measures ?? []).filter(m => m.representation === 'line')
    const measuresRepresentedByTimeBar = [
      ...(this.props.binaryWords ?? []).reduce(
        (acc: WordSupportedMeasures[], word) => [...acc, ...supportedMeasuresByWord[word.type]],
        []
      ),
      ...(this.props.measures ?? []).filter(m => m.representation === 'time-bar').map(m => m.name),
    ]

    return (
      <div className="col-md-12 col-sm-12 col-lg-12">
        {this.props.title && 
          <div className='mb-3'>
            <h3 className={'w-100'}>{this.props.title}</h3>
          </div>
        }
        <div className="row">
          {noData && (
            <div className="alert alert-warning w-100 col-sm-6 mx-auto">
              {this.props.t('plantAnalysis.noDataAvailable')}
            </div>
          )}
          {this.state.isLoading && (
            <Row>
              <Loader />
            </Row>
          )}
          {this.state.fetchErrors && (
            <div className="alert alert-danger w-100 col-sm-6 mx-auto">
              {this.props.t('plantAnalysis.fetchErrors')}
            </div>
          )}
        </div>

        {isReady && measuresRepresentedByLine.length > 0 && (
          <div className="row">
            <GenericLineGraph
              additionalChartLines={measuresRepresentedByLine.map(m => ({
                type: m.unitOfMeasure === '%' ? 'perc' : 'value',
                measure: m.name,
                unit: m.unitOfMeasure,
                color: m.color,
                label: m.label
              }))}
              filteredData={this.state.filteredData}
              warnAreasData={(this.props.warnAreas ?? [])}
            />
          </div>
        )}

        {isReady &&
          measuresRepresentedByTimeBar.map(m => (
            <GenericTimeBarGraph
              topMargin={true}
              entry={m}
              filteredData={this.state.filteredData}
              paddingRight={74}
            />
          ))}

        {isReady && <GraphTimelineView data={this.state.filteredData} xAxisNumber={true} paddingRight={60} />}

        {isReady && <BottomLegend measuresSlugs={measuresRepresentedByTimeBar} />}

        {isReady && measuresRepresentedByLine.length > 0 && (
          <GraphBrush data={this.state.preparedData} brushOnChange={this.handleBrush} paddingRight={70}>
            <Line
              dataKey={(this.props.measures ?? [])?.[0]?.name}
              type="step"
              stroke="#A19FF9"
              dot={false}
              isAnimationActive={false}
            />
          </GraphBrush>
        )}

        {isReady && measuresRepresentedByLine.length > 0 && (
          <GraphTimelineView data={this.state.preparedData} xAxisNumber={false} paddingRight={60} />
        )}
      </div>
    )
  }

  private async getData(props: Props) {
    try {
      this.setState({ isLoading: true })

      const startDateObj = moment(props.workshift.start)
      const startDate = startDateObj
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
      const endDateObj = moment(props.workshift.end)
      const endDate = endDateObj
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()

      const plantQuery = props.plant ? 'plant=' + props.plant + '&' : ''

      const queryStart = `SELECT "measure" FROM "`
      const queryEnd = `" WHERE time >= '${startDate}' AND time <= '${endDate}'`
      const queryLastStart = ` SELECT last("measure") AS "last_measure" FROM "`
      const queryLastEnd = `" WHERE time < '${startDate}' LIMIT 1`

      const wordsPromises = (this.props.binaryWords ?? []).map(word => {
        const assetId = word.assetId ?? this.props.id
        const queryWord = queryStart + assetId + `_${word.name}` + queryEnd
        const queryLastWord = queryLastStart + assetId + `_${word.name}` + queryLastEnd

        const requestWordData = API().request(`/query?${plantQuery}q=` + queryWord)
        const requestLastWordData = API().request(`/query?${plantQuery}q=` + queryLastWord)
        return [requestWordData, requestLastWordData]
      })

      const measuresPromises = (this.props.measures ?? []).map(measure => {
        const assetId = measure.assetId ?? this.props.id
        const query = queryStart + assetId + `_${measure.slug}` + queryEnd
        const queryLast = queryLastStart + assetId + `_${measure.slug}` + queryLastEnd

        const requestMeasureData = API().request(`/query?${plantQuery}&q=` + query, {
          signal: this.abortController.signal,
        })
        const requestLastMeasureData = API().request(`/query?${plantQuery}&q=` + queryLast, {
          signal: this.abortController.signal,
        })
        return [requestMeasureData, requestLastMeasureData]
      }, [])

      for (let index = 0; index < wordsPromises.length; index++) {
        const wordPromises = wordsPromises[index]
        const [data, last] = await Promise.all(wordPromises)
        this.setState({
          wordsRawData: Object.assign({}, this.state.wordsRawData, {
            [(this.props.binaryWords ?? [])?.[index]?.name]: { data: parseResponseData(data), min: 0, max: 0 },
          }),
          wordsRawDataLast: Object.assign({}, this.state.wordsRawDataLast, {
            [(this.props.binaryWords ?? [])?.[index]?.name]: { data: parseResponseSingleData(last), min: 0, max: 0 },
          }),
        })
      }

      for (let index = 0; index < measuresPromises.length; index++) {
        const measurePromises = measuresPromises[index]
        const [data, last] = await Promise.all(measurePromises)
        this.setState({
          data: Object.assign({}, this.state.data, {
            [(this.props.measures ?? [])?.[index]?.name]: { data: parseResponseData(data), min: 0, max: 0 },
          }),
          last: Object.assign({}, this.state.last, {
            [(this.props.measures ?? [])?.[index]?.name]: { data: parseResponseSingleData(last), min: 0, max: 0 },
          }),
        })
      }

      this.setState(
        {
          startDate: startDateObj.unix(),
          endDate: endDateObj.unix(),
          isLoading: false,
          fetchErrors: false,
        },
        () => {
          this.prepareData()
        }
      )
    } catch (error: any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        logoutUser()
      }
      this.setState({
        isLoading: false,
        fetchErrors: true,
      })
    }
  }

  private populateSingleData(key: string, isBoolean: boolean = false, multiplier?: number): SingleDataValue[] {
    const result: SingleDataValue[] = []
    const measureData = this.state.data[key]
    const measureDataLast = this.state.last[key]

    if (!measureData || !measureDataLast) {
      return []
    }

    if (measureDataLast.data.length > 0) {
      const time = moment(this.props.workshift.start).unix()
      result.push({
        x: time,
        y: measureDataLast.data[1],
        h100: 100,
      })
    }

    if (measureData.data.length > 0) {
      measureData.data.forEach((datum: [string, number]) => {
        const time = moment(datum[0]).unix()

        if (measureData.min === 0 || time < measureData.min) {
          measureData.min = time
        }
        if (time > measureData.max) {
          measureData.max = time
        }
        result.push({
          x: time,
          y: datum[1],
          h100: 100,
        })
      })
    }
    return result
  }

  private populateSingleDataFromBinaryStatus(
    key: BaseWordMeasures,
    wordSlug: SupportedWordsSlugs,
    wordName: string
  ): SingleDataValue[] {
    const result: SingleDataValue[] = []

    const wordData = this.state.wordsRawData[wordName]
    const wordDataLast = this.state.wordsRawDataLast[wordName]
    if (!wordData || !wordDataLast) {
      return []
    }

    if (wordDataLast.data.length > 0 && wordSlug) {
      const time = moment(this.props.workshift.start).unix()
      result.push({
        x: time,
        y: getWordValueFromBinaryMapping(key, wordSlug, wordDataLast.data[1]),
        h100: 100,
      })
    }

    if (wordData.data.length > 0 && wordSlug) {
      wordData.data.forEach((datum: [string, number]) => {
        const time = moment(datum[0]).unix()

        if (wordData.min === 0 || time < wordData.min) {
          wordData.min = time
        }
        if (time > wordData.max) {
          wordData.max = time
        }
        result.push({
          x: time,
          y: getWordValueFromBinaryMapping(key, wordSlug, datum[1]),
          h100: 100,
        })
      })
      return result
    }
    return result
  }

  private prepareData() {
    try {
      const populatedMeasuresObject: { [key: string]: any[] } = {}
      const derivedMeasuresToFill: DerivedWordMeasures[] = (this.props.binaryWords ?? []).reduce(
        (acc: DerivedWordMeasures[], value) => {
          return [
            ...acc,
            ...(supportedMeasuresByWord[value.type]?.filter(m => derivedWordMeasures.includes(m as any)) ?? []),
          ] as DerivedWordMeasures[]
        },
        []
      )

      if (this.state.wordsRawData) {
        (this.props.binaryWords ?? []).forEach(word => {
          const supportedMeasures = supportedMeasuresByWord[word.type]
          supportedMeasures.forEach(measure => {
            const fieldMeasureIsDerived = binaryFieldsForWordMeasure[measure] as BaseWordMeasures[] | undefined
            if (fieldMeasureIsDerived) {
              fieldMeasureIsDerived.forEach(field => {
                const data = this.populateSingleDataFromBinaryStatus(field, word.type, word.name)
                populatedMeasuresObject[field] = data
              })
            } else {
              const data = this.populateSingleDataFromBinaryStatus(measure as BaseWordMeasures, word.type, word.name)
              populatedMeasuresObject[measure] = data
            }
          })
        })
      }

      if (this.state.data) {
        (this.props.measures ?? []).forEach(measure => {
          populatedMeasuresObject[measure.name] = this.populateSingleData(measure.name)
        })
      }

      const hydratedData = hydrateData(
        populatedMeasuresObject,
        derivedMeasuresToFill,
        [new Date(this.props.workshift.start), new Date(this.props.workshift.end)],
        this.props.warnAreas ?? []
      )

      this.setState({
        brushStartIndex: 0,
        brushEndIndex: hydratedData.length,
        preparedData: hydratedData,
        filteredData: hydratedData,
      })
    } catch (err) {
      consoleLog('ERROR', err)
      this.setState({
        isLoading: false,
        fetchErrors: true,
      })
    }
  }

  private exportData(e: React.MouseEvent) {
    e.preventDefault()
  }

  private handleBrush(args: any) {
    const filteredData = this.state.preparedData
      ? this.state.preparedData.slice(args.startIndex, args.endIndex + 1)
      : []
    this.setState({
      brushStartIndex: args.startIndex,
      brushEndIndex: args.endIndex,
      filteredData,
    })
  }
}

export default withRouter<any, any>(connect(mapStateToProps, null)(withTranslation()(GeneralBinarySrcGraph)))
