import {
  InfluxResponseToParse,
  parseInfluxResponse,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/influx'
import { IInfluxResponse, InfluxMetricQueryParams } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/influx'

const engineTorqueDensifierMeasuresToFetch: Array<InfluxMetricQueryParams & InfluxResponseToParse> = [
  {
    type: 'summary',
    id: 'vDecSpeedTorque',
    slug: 'decSpeedTorque',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'summary' }),
  },
  {
    type: 'summary',
    id: 'vDecUptime',
    slug: 'decUptime',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'summary' }),
  },
]

export { engineTorqueDensifierMeasuresToFetch }
