import { EngineTorqueDensifierState } from './_types'

const ENGINE_TORQUE_DENSIFIER_GRAPH_KEYS_TO_IGNORE = [
  'seconds_over_zero_threshold',
  'seconds_under_zero_threshold',
  'average_torque',
  'seconds_running',
  'seconds_stopped',
  'speed_over_torque_threshold_percent',
  'over_threshold_count',
  'seconds_from_zero_to_one_point_five',
]

const DEFAULT_STATE: EngineTorqueDensifierState = {
  fetching: true,
  error: false,
  data: null,
}

export {  DEFAULT_STATE, ENGINE_TORQUE_DENSIFIER_GRAPH_KEYS_TO_IGNORE }
