import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { AreaSection } from '../../../../types/measure'
import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { COLORS } from '../../../../constants'
import moment from 'moment'
import { getAreaPauses, getAreaSections, HHHmmssFromMilliseconds, reduceWarnAreas } from '../../../../functions/shared'
import Row from '../../../../../inplant-components-fe/ui/components/Grid/Row'
import { consoleLog } from '../../../../../inplant-components-fe/mvfunctions/logs'
import Column from '../../../../../inplant-components-fe/ui/components/Grid/Column'
import Untha from './_Vecoplan'
import { VecoplanProps, VecoplanState } from './_types'
import { DEFAULT_STATE } from './_constants'
import { mapChartStateToProps } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/state'

interface VecoplanViewState extends VecoplanState {
  tooltipData: any
  showTooltip: boolean
}

function propsToOwnState(props: VecoplanProps): VecoplanViewState {
  return {
    ...DEFAULT_STATE,
    showTooltip: true,
    tooltipData: null,
  }
}

class VecoplanView extends Untha<VecoplanProps, VecoplanViewState> {
  constructor(props: VecoplanProps) {
    super(props, propsToOwnState)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  private handleMouseEnter() {
    this.setState({
      showTooltip: true,
    })
  }

  private handleMouseLeave() {
    this.setState({
      showTooltip: false,
      tooltipData: null,
    })
  }

  private handleMouseMove(args: any) {
    if (args && args.activeLabel) {
      try {
        const curData = this.state.filteredData && this.state.filteredData.find((e: any) => e.time === args.activeLabel)
        const curThrustPresser = curData.thrustPresser !== undefined ? curData.thrustPresser : '--'
        const curSpeedPercent = curData.speedPercent !== undefined ? curData.speedPercent : '--'
        this.setState({
          showTooltip: true,
          tooltipData: {
            time: curData.time,
            thrustPresser: Number(curThrustPresser).toFixed(1),
            thrustPresserColor: COLORS.vecoplant.generalGraph.thrustPresser,
            speedPercent: Number(curSpeedPercent).toFixed(1),
            speedPercentColor: COLORS.vecoplant.generalGraph.speedPercent,
          },
        })
      } catch (error) {
        consoleLog('error', error)
      }
    }
  }

  private static nullTooltipContent() {
    return <div className="null-tooltip-content" />
  }

  public renderContent() {
    const { t, pauses } = this.props

    const warnPoints: AreaSection[] | undefined = getAreaSections(this.state.filteredData, 'warnArea').filter(
      e => e.value === 100
    )
    const warnPauses = pauses && reduceWarnAreas(getAreaPauses(pauses), undefined, COLORS.pauses)
    const warnAreas = reduceWarnAreas(warnPoints)
    return (
      <Column>
        <Row>
          <div className={`col-md-12 col-sm-12 col-lg-12 `}>
            <h3 className={'w-100'}>{this.props.t(this.chartTranslationKeyTitle)}</h3>
            <Row>
              <div className="mv-col col-md-11 col-sm-11 col-lg-11 print-full-width">
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart
                    data={this.state.filteredData}
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseMove={this.handleMouseMove}
                    onMouseLeave={this.handleMouseLeave}
                    barCategoryGap={0}
                  >
                    <XAxis
                      key={'line1'}
                      hide={false}
                      dataKey="time"
                      label={''}
                      height={30}
                      axisLine={true}
                      tickLine={false}
                      tick={true}
                      interval={'preserveStartEnd'}
                      minTickGap={50}
                      domain={['dataMin', 'dataMax']}
                      tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                      type={'category'}
                    />
                    <CartesianGrid stroke="#f5f5f5" />

                    {warnAreas}
                    {warnPauses}
                    <Tooltip content={VecoplanView.nullTooltipContent} />
                    <YAxis
                      yAxisId={'percentage'}
                      domain={[0, 100]}
                      axisLine={false}
                      tickFormatter={val => parseInt(val, 10)}
                    >
                      <Label value={'%'} position={'insideTopLeft'} />
                    </YAxis>
                    <Line
                      dataKey="thrustPresser"
                      stroke={COLORS.vecoplant.generalGraph.thrustPresser}
                      dot={false}
                      strokeWidth={2}
                      type="step"
                      isAnimationActive={false}
                      yAxisId={'percentage'}
                    />
                    <Line
                      dataKey="speedPercent"
                      stroke={COLORS.vecoplant.generalGraph.speedPercent}
                      dot={false}
                      strokeWidth={2}
                      type="step"
                      isAnimationActive={false}
                      yAxisId={'percentage'}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div className="mv-col col-md-1 col-sm-1 col-lg-1 d-print-none">
                {this.state.showTooltip && this.state.tooltipData !== null && (
                  <ul className="side-tooltip">
                    <li>
                      <strong>{this.props.t('plantAnalysis.labels.time')}</strong>
                      <br />
                      <span>{moment.unix(this.state.tooltipData.time).format('HH:mm')}</span>
                    </li>
                    {this.state.tooltipData.thrustPresser !== null && (
                      <li>
                        <strong>{this.props.t('plantAnalysis.labels.thrustPresser')}</strong>
                        <br />
                        <span
                          className="label-color label-color-line"
                          style={{ backgroundColor: `${this.state.tooltipData.thrustPresserColor}` }}
                        />
                        <span>{this.state.tooltipData.thrustPresser} %</span>
                      </li>
                    )}
                    {this.state.tooltipData.speedPercent !== null && (
                      <li>
                        <strong>{this.props.t('plantAnalysis.labels.speedPercent')}</strong>
                        <br />
                        <span
                          className="label-color label-color-line"
                          style={{ backgroundColor: `${this.state.tooltipData.speedPercentColor}` }}
                        />
                        <span>{this.state.tooltipData.speedPercent} %</span>
                      </li>
                    )}
                    <li>
                      <strong>{this.props.t('plantAnalysis.labels.pauses')}</strong>{' '}
                      <span
                        className="label-color label-color-line label-color-line-right"
                        style={{ backgroundColor: COLORS.pauses }}
                      />
                    </li>
                  </ul>
                )}
              </div>
            </Row>
            <div className="d-none d-print-inline-flex">
              <Row verticalAlignment="center">
                <span style={{ whiteSpace: 'nowrap' }}>
                  <span
                    className="label-color-square"
                    style={{ backgroundColor: COLORS.vecoplant.generalGraph.thrustPresser }}
                  />
                  <span>{t('plantAnalysis.labels.thrustPresser')}</span>
                </span>
              </Row>
              <Row verticalAlignment="center">
                <span style={{ whiteSpace: 'nowrap' }}>
                  <span
                    className="label-color-square"
                    style={{ backgroundColor: COLORS.vecoplant.generalGraph.speedPercent }}
                  />
                  <span>{t('plantAnalysis.labels.speedPercent')}</span>
                </span>
              </Row>
            </div>
          </div>
        </Row>
        <div className={`col-lg-12 col-md-12 col-sm-12 chart-events-pie--data`}>
          {this.state.data?.recapData && (
            <>
              {this.state.data.recapData.stops_count !== undefined && (
                <span className="mx-5">
                  <b>{this.props.t('plantAnalysis.vecoplant.vecoplanTable.number') + ': '}</b>
                  {this.state.data.recapData.stops_count}
                </span>
              )}
              {this.state.data.recapData.stop_seconds !== undefined && (
                <span className="mx-5">
                  <b>{this.props.t('plantAnalysis.vecoplant.vecoplanTable.time') + ': '}</b>
                  {HHHmmssFromMilliseconds(this.state.data.recapData.stop_seconds * 1000, false, true, false)}
                </span>
              )}
              {this.state.data.recapData.average_speed !== undefined && (
                <span className="mx-5">
                  <b>{this.props.t('plantAnalysis.vecoplant.vecoplanTable.averageSpeed') + ': '}</b>
                  {Number(this.state.data.recapData.average_speed).toFixed(2)}
                </span>
              )}
              {this.state.data.recapData.average_presser_push !== undefined && (
                <span className="mx-5">
                  <b>{this.props.t('plantAnalysis.vecoplant.vecoplanTable.averageThrustPresser') + ': '}</b>
                  {Number(this.state.data.recapData.average_presser_push).toFixed(2)}
                </span>
              )}
              {this.state.data.recapData.reverse_count_actual_number !== undefined && (
                <span className="mx-5">
                  <b>{this.props.t('plantAnalysis.vecoplant.vecoplanTable.reverseCountActualNumber') + ': '}</b>
                  {Number(this.state.data.recapData.reverse_count_actual_number)}
                </span>
              )}
            </>
          )}
        </div>
      </Column>
    )
  }
}

export default connect(mapChartStateToProps)(withTranslation()(VecoplanView))
