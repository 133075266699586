import {
  InfluxResponseToParse,
  parseInfluxResponse,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/influx'
import { IInfluxResponse, InfluxMetricQueryParams } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/influx'

const lineBufferMeasuresToFetch: Array<InfluxMetricQueryParams & InfluxResponseToParse> = [
  {
    type: 'state-serie',
    id: 'vBufferF019ActiveStateTimeLine',
    slug: 'buffer1State',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'single_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vBufferF019AFullStateTimeLine',
    slug: 'buffer1Fill',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'single_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vBufferF017LoadState',
    slug: 'buffer1LoadState',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'multi_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vDensifierF019TorqueState',
    slug: 'dec1TorqueState',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'multi_column_state_serie' }),
  },

  {
    type: 'state-serie',
    id: 'vBufferF028ActiveStateTimeLine',
    slug: 'buffer2State',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'single_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vBufferF028AFullStateTimeLine',
    slug: 'buffer2Fill',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'single_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vBufferF026LoadState',
    slug: 'buffer2LoadState',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'multi_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vDensifierF028TorqueState',
    slug: 'dec2TorqueState',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'multi_column_state_serie' }),
  },

  {
    type: 'state-serie',
    id: 'vBufferF057ActiveStateTimeLine',
    slug: 'buffer5State',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'single_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vBufferF057AFullStateTimeLine',
    slug: 'buffer5Fill',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'single_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vBufferF055LoadState',
    slug: 'buffer5LoadState',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'multi_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vDensifierF057TorqueState',
    slug: 'dec5TorqueState',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'multi_column_state_serie' }),
  },

  {
    type: 'state-serie',
    id: 'vBufferF076ActiveStateTimeLine',
    slug: 'buffer3State',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'single_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vBufferF076AFullStateTimeLine',
    slug: 'buffer3Fill',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'single_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vBufferF074LoadState',
    slug: 'buffer3LoadState',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'multi_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vDensifierF076TorqueState',
    slug: 'dec3TorqueState',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'multi_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vDensifierF086TorqueState',
    slug: 'dec4TorqueState',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'multi_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vBufferF086ActiveStateTimeLine',
    slug: 'buffer4State',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'single_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vBufferF086AFullStateTimeLine',
    slug: 'buffer4Fill',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'single_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vBufferF084LoadState',
    slug: 'buffer4LoadState',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'multi_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vMillF024AbsorptionState',
    slug: 'mill1AbsorptionState',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'multi_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vMillF033AbsorptionState',
    slug: 'mill2AbsorptionState',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'multi_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vMillF083AbsorptionState',
    slug: 'mill3AbsorptionState',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'multi_column_state_serie' }),
  },
  {
    type: 'state-serie',
    id: 'vMillF093AbsorptionState',
    slug: 'mill4AbsorptionState',
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'multi_column_state_serie' }),
  },
]

export { lineBufferMeasuresToFetch }
